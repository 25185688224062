/* About.css */

.about-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
  }
  
  .about-container h1,
  .about-container h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-container h1 {
    font-size: 2em;
  }
  
  .about-container h2 {
    font-size: 1.8em;
  }
  
  .about-container p,
  .about-container ul {
    color: #333;
    line-height: 1.6;
    margin-bottom: 15px;
    text-align: justify;
    padding: 0 20px;
  }
  
  .about-container ul {
    list-style-position: inside;
  }
  
  .about-container li {
    margin-bottom: 10px;
  }
  
  .join-us {
    font-style: italic;
    text-align: center;
  }
  
  /* Additional Styling for Emphasis or Highlights */
  .mission p,
  .our-story p {
    font-weight: 500; /* Slightly bolder text for emphasis */
  }
  